import Spline from "@splinetool/react-spline";
import Marquee from "react-fast-marquee";
// import HomeBlogCard from "./HomeBlogCard";
// import { homeBlogs } from "./utils";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// const settings = {
//   dots: window.innerWidth < 1200 ? true : homeBlogs.length > 4 ? true : false,
//   arrows: false,
//   infinite: false,
//   speed: 500,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 1200,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         infinite: true,
//         dots: true,
//       },
//     },
//     {
//       breakpoint: 900,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         initialSlide: 2,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

function Home() {
  return (
    <div className="app">
      <div className="top-area">
        <Spline scene="https://prod.spline.design/5aCIZ-xd2aTE5Tp1/scene.splinecode" />
      </div>
      <div className="marquee-area">
        <div className="container">
          <div className="left-shedow">
            <img src="../images/img-left-shedow.png" alt="left-shedow" />
          </div>
          <div className="right-shedow">
            <img src="../images/img-right-shedow.png" alt="left-shedow" />
          </div>
          <Marquee>
            <div className="marquee-wrap">
              <div className="murqee-logo">
                <img src="../images/logo-mhl.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-meta.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-team.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-emplofive.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-spheron.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-gaiming.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-rl.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-pg.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-surg.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-eth.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/webthree.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-coinsee.svg" alt="logo-arcadia" />
              </div>
              <div className="murqee-logo">
                <img src="../images/logo-calsoft.svg" alt="logo-arcadia" />
              </div>
            </div>
          </Marquee>
        </div>
      </div>
      {/* NOTE: uncomment it to showcase the blogs */}
      {/* <section className="home-blog">
        <p className="title">BLOGS</p>
        <p className="desc">
          Stay up to date with iAgent! The role of blockchain technology in
          securing online transactions and data
        </p>
        <div className="home-blog-container">
          <Slider {...settings}>
            {homeBlogs.map((item, i) => {
              return <HomeBlogCard key={i} {...item} />;
            })}
          </Slider>
        </div>
      </section> */}
      <section className="block-articles">
        <div className="shadow-holder">
          <img src="./images/shadow.png" alt="shadow" />
        </div>
        <div className="shadow-holder right">
          <img src="./images/shadow.png" alt="shadow" />
        </div>
        <div className="container">
          <article className="article">
            <div className="text-holder">
              <h2 className="h1">
                ENHANCE YOUR <span className="header-purple">GAMING</span> WITH
                iAGENT
              </h2>
              <p>
                At iAgent, we know personally what it is like in e-sports to
                lack control of game assets. Our mission is to democratize
                gaming by creating Agents as digital assets on the blockchain
                and providing all gamers with the tools and infrastructure to
                train their own Agents with ease.
              </p>
              <a
                href="https://docs.iagentpro.com/product-and-ecosystem"
                target="_blank"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6569 8H8M8 8V13.6569M8 8L16.364 16.364M18.8278 11.7568C19.3917 8.90961 18.5704 5.84251 16.364 3.63604C12.8492 0.12132 7.15076 0.12132 3.63604 3.63604C0.12132 7.15076 0.12132 12.8492 3.63604 16.364C5.84251 18.5704 8.90961 19.3917 11.7568 18.8278"
                    stroke="#8E43DF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Read more
              </a>
            </div>
            <div className="image-holder">
              <img src="./images/vertical-abstract.jpg" alt="gaming" />
            </div>
          </article>
          <article className="article reverse-article">
            <div className="text-holder">
              <h2 className="h1">
                THE FUTURE IS <span className="header-green">GREEN</span> WITH
                iAGENT
              </h2>
              <p>
                Sustainability is not just a buzzword; it's a guiding principle
                that shapes our technology and operations. iAgent is committed
                to reducing the environmental impact of AI and ML training and
                digital innovation. We emphasize not just innovation, but also
                collaboration towards sustainability
              </p>
              <a
                href="https://docs.iagentpro.com/we-are-green-depin#"
                className="green"
                target="_blank"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6569 8H8M8 8V13.6569M8 8L16.364 16.364M18.8278 11.7568C19.3917 8.90961 18.5704 5.84251 16.364 3.63604C12.8492 0.12132 7.15076 0.12132 3.63604 3.63604C0.12132 7.15076 0.12132 12.8492 3.63604 16.364C5.84251 18.5704 8.90961 19.3917 11.7568 18.8278"
                    stroke="#00FF00"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Read more
              </a>
            </div>
            <div className="image-holder">
              <img src="./images/green-loop.gif" alt="gaming" />
            </div>
          </article>
        </div>
      </section>
      <div className="middle-block">
        <div className="shadow-holder">
          <img src="./images/shadow.png" alt="shadow" />
        </div>
        <div className="shadow-holder right">
          <img src="./images/shadow.png" alt="shadow" />
        </div>
        <section className="block-info">
          <div className="container">
            <h2 className="h1">TRAIN YOUR AI AGENTS WITH iAGENT</h2>
            <div className="three-cols">
              <div className="col">
                <div className="col-wrap">
                  <strong className="title">Ai Agents</strong>
                  <div className="image">
                    <img src="./images/user.png" alt="user" />
                  </div>
                  <p>
                    Agents represent players' gaming strategies, styles, and
                    creativity all in one, a digital representation of their
                    gaming persona.
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="col-wrap">
                  <strong className="title">Powered by DePIN</strong>
                  <div className="image">
                    <img src="./images/GPU.png" alt="GPU" />
                  </div>
                  <p>
                    iAgent leverages scattered, underutilized GPU resources
                    around the world into a powerful, distributed GPU network
                    dedicated to training Agents.
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="col-wrap">
                  <strong className="title">On-chain Asset</strong>
                  <div className="image">
                    <img src="./images/network.png" alt="chain" />
                  </div>
                  <p>
                    Agent ownership is assigned to the creator, and it is
                    recorded on the blockchain, thereby creating your Agent as a
                    new on-chain asset class.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="block-protocol">
          <div className="container">
            <div className="agent-protocol">
              <div className="text-holder">
                <h2>iAgent Protocol Nodes</h2>
                <a
                  href="https://docs.iagentpro.com/protocol-nodes"
                  target="_blank"
                  className="agent_link"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.6569 8H8M8 8V13.6569M8 8L16.364 16.364M18.8278 11.7568C19.3917 8.90961 18.5704 5.84251 16.364 3.63604C12.8492 0.12132 7.15076 0.12132 3.63604 3.63604C0.12132 7.15076 0.12132 12.8492 3.63604 16.364C5.84251 18.5704 8.90961 19.3917 11.7568 18.8278"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Coming soon ...
                </a>
              </div>
              <div className="image-holder">
                <img src="./images/img-protocol.png" alt="protocol" />
              </div>
            </div>
          </div>
        </section>
        <section className="block-team">
          <div className="container">
            <header className="head">
              <h2 className="h1">iAGENT TEAM</h2>
              <p>
                For over Two decades we have shipped end to end products, gained
                over 20 years of fortune 500 experience.
              </p>
            </header>
            <div className="row">
              <div className="col">
                <div className="team-block">
                  <div className="image-holder">
                    <a href="https://twitter.com/jamkaa_mgl" target="_blank">
                      <img src="./images/img-jamie.png" alt="jamie" />
                    </a>
                  </div>
                  <div className="text-holder">
                    <a href="https://twitter.com/jamkaa_mgl" target="_blank">
                      {" "}
                      <strong className="name">Jamie Batzorig</strong>{" "}
                    </a>
                    <span className="designation">CEO & Co-founder</span>
                    <p>
                      Former Pro Gamer and a passionate entrepreneur. Founded
                      architecture company in Canada 2010, a successful exit in
                      2019.
                      <br /> Started PG top web3 project in October 2021.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-block">
                  <div className="image-holder">
                    <a href="https://twitter.com/space_monk_" target="_blank">
                      <img src="./images/img-raju.png" alt="jamie" />
                    </a>
                  </div>
                  <div className="text-holder">
                    <a href="https://twitter.com/space_monk_" target="_blank">
                      {" "}
                      <strong className="name">Raju Randhav</strong>{" "}
                    </a>
                    <span className="designation">CPO & Co-founder</span>
                    <p>
                      Ex-researcher at Samsung and Airbus, Recipient of numerous
                      design awards and contributor on 2 Patents - Samsung
                      Foldable Devices.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-block">
                  <div className="image-holder">
                    <a href="https://twitter.com/ryuk_nl" target="_blank">
                      <img src="./images/img-anbarivan.png" alt="jamie" />
                    </a>
                  </div>
                  <div className="text-holder">
                    <a href="https://twitter.com/ryuk_nl" target="_blank">
                      {" "}
                      <strong className="name">Anbarivan NL.</strong>
                    </a>
                    <span className="designation">
                      Chief Technology Officer
                    </span>
                    <p>
                      Over 5+ publications in international journals on AI
                      domain and 2 Patents focused on applying AI to automotive
                      prognostics in fortune 500 companies.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-block">
                  <div className="image-holder">
                    <a href="https://twitter.com/Mr_HmmmNFT" target="_blank">
                      <img src="./images/img-anthony.png" alt="jamie" />
                    </a>
                  </div>
                  <div className="text-holder">
                    <a href="https://twitter.com/Mr_HmmmNFT" target="_blank">
                      <strong className="name">Anthony Gonzalez</strong>
                    </a>
                    <span className="designation">Chief Marketing Officer</span>
                    <p>
                      15 years Fortune 500 experience- sales, business dev, and
                      management, owner of one of the top development firms in
                      web3, and have personally worked with over 300 projects.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-block">
                  <div className="image-holder">
                    <a href="https://twitter.com/jkong910" target="_blank">
                      <img src="./images/img-james.png" alt="jamie" />
                    </a>
                  </div>
                  <div className="text-holder">
                    <a href="https://twitter.com/jkong910" target="_blank">
                      <strong className="name">James Kong</strong>
                    </a>
                    <span className="designation">Chief Finance Officer</span>
                    <p>
                      Web 2 - Corporate Finance Professional spanning over 20
                      years for various Fortune 100 companies. Web3 - Finance /
                      tokenomics / business advisor for PG (Project Godjira)
                      actively since 2021.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-block">
                  <div className="image-holder">
                    <img src="./images/img-kart.png" alt="jamie" />
                  </div>
                  <div className="text-holder">
                    <strong className="name">Viacheslav Kartushin</strong>
                    <span className="designation">Chief Security Officer</span>
                    <p>
                      Accomplished Blockchain (Solidity) and AI (Python)
                      Developer with a rich background in Fintech, managing
                      technical teams, and strategic project implementation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="team-block">
                  <div className="image-holder">
                    <a href="https://twitter.com/mhl_eth" target="_blank">
                      <img src="./images/img-mark.png" alt="jamie" />
                    </a>
                  </div>
                  <div className="text-holder">
                    <a href="https://twitter.com/mhl_eth" target="_blank">
                      <strong className="name">Mark Mhilli</strong>
                    </a>
                    <span className="designation">Tokenomics Consultant</span>
                    <p>
                      Web3 business and tokenomics consultant with 8 years of
                      experience as a crypto investor in the industry. Founder
                      of MHL Solutions a Web3 consulting company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <strong className="f-logo">
              <a href="#">
                <img src="../images/site-logo.svg" alt="iAGENT PROTOCOL" />
              </a>
            </strong>
            <div className="links-area">
              <div className="col-holder">
                <strong className="title">Reach Out</strong>
                <ul className="footer-links">
                  <li>
                    <a
                      href="https://www.linkedin.com/company/iagent-protocol"
                      target="_blank"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/iAgentProtocol"
                      target="_blank"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a href="https://discord.gg/iagentprotocol" target="_blank">
                      Discord
                    </a>
                  </li>
                  <li>
                    <a href="#">Telegram</a>
                  </li>
                </ul>
              </div>
              <div className="col-holder">
                <strong className="title">Resources</strong>
                <ul className="footer-links">
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="mailto:jamie@iagentpro.com">Careers</a>
                  </li>
                  <li>
                    <a href="https://docs.iagentpro.com/" target="_blank">
                      Docs
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.iagentpro.com/official-links"
                      target="_blank"
                    >
                      Partners
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="posts-area">
              <strong className="title">Latest updates</strong>
              <div className="posts-wrap">
                <div className="post-holder">
                  <div className="image-holder">
                    <img src="./images/img-update.svg" alt="iAGENT PROTOCOL" />
                  </div>
                  <div className="text-holder">
                    <p>Coming Soon.</p>
                  </div>
                </div>
                <div className="post-holder">
                  <div className="image-holder">
                    <img src="./images/img-update.svg" alt="iAGENT PROTOCOL" />
                  </div>
                  <div className="text-holder">
                    <p>Coming Soon.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>&copy;2024 iAgent Corporation (USA) | All rights reserved</p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
